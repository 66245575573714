<template>
    <div class="vut-banner">
        <div v-if="banner.imageUrl || banner.mobileImageUrl" class="vut-banner__img-box">
            <!-- TODO wrapper component? https://github.com/simplo-sro/simploshop-fe/issues/650 -->
            <CoreImg
                v-for="(item, index) in images"
                :key="index"
                :src="item.src"
                alt=""
                sizes="100vw xs:100vw sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw"
                :class="['size-full object-cover', item.class]"
                :placeholder="preload ? undefined : [58, 50, 100, 2]"
                :preload="preload"
                :loading="preload ? 'eager' : 'lazy'"
            />
        </div>
        <div class="vut-banner__text-box absolute">
            <h1 class="vut-banner__title">
                <span class="vut-banner__title-content">
                    {{ banner.name }}
                </span>
            </h1>

            <BaseUiButton
                v-if="banner.buttonText && (banner.buttonLink || banner.url)"
                color="secondary"
                variant="plain"
                :to="banner.buttonLink ?? banner.url"
            >
                {{ banner.buttonText }}
            </BaseUiButton>
        </div>
    </div>
</template>

<script lang="ts" setup>
const {
    banner,
    preload,
} = defineProps<{
    banner: InstanceType<typeof BannerModel>
    preload?: boolean
}>()

const images = computed((): { src: string | null | undefined; class?: string }[] => {
    if (banner.imageUrl && banner.mobileImageUrl) {
        return [
            {
                src: banner.imageUrl,
                class: 'hidden sm:flex md:hidden lg:flex',
            },
            {
                src: banner.mobileImageUrl,
                class: 'sm:hidden md:flex lg:hidden',
            },
        ]
    } else {
        return [
            {
                src: banner.imageUrl ?? banner.mobileImageUrl,
            },
        ]
    }
})
</script>

<style lang="scss" scoped>
.vut-banner {
    position: relative;

    display: flex;
    align-items: flex-end;

    height: 100%;
    width: 100%;

    aspect-ratio: 4 / 5;

    @include more-than(sm) {
        aspect-ratio: 2 / 1;
    }

    @include more-than(md) {
        aspect-ratio: 4 / 5;
    }

    @include more-than(lg) {
        aspect-ratio: 2 / 1;
    }
}

.vut-banner__img-box {
    position: absolute;
    inset: 0;

    z-index: -1;
}

.vut-banner__text-box {
    display: flex;
    align-items: start;
    flex-direction: column;

    padding: 1.25rem 1.25rem 5.25rem;

    @include more-than(xl) {
        padding: 5.25rem;
    }

    @include more-than(xxl) {
        max-width: 50%;
    }
}

.vut-banner__title {
    font-size: 1.625rem;
    line-height: 2rem;
    font-family: $vut-ff-secondary;
    padding: 1rem 0;

    @include more-than(lg) {
        font-size: 2.75rem;
        line-height: 3.75rem;
    }
}

.vut-banner__title-content {
    padding: 0.25rem 1rem;
    color: $vut-c-white;
    background: $vut-c-primary;
    box-decoration-break: clone;

    @include more-than(lg) {
        padding: 0.5rem 1rem;
    }
}
</style>
