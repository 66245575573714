<template>
    <div>
        <NoticeHomepage />

        <!--  -------------------------------------------------------------------------------- -->
        <!--  BANNERS  -->
        <CoreContainer v-if="bannersResponse?.length">
            <BaseContainerContent :padding="{top: 'small', right: ['safe-only', 'md:normal-only'], bottom: 'large', left: ['safe-only', 'md:normal-only', 'lg:small-only'] }">
                <SwiperBanner :banners="bannersResponse" />
            </BaseContainerContent>
        </CoreContainer>

        <!--  -------------------------------------------------------------------------------- -->
        <!--  TOP CATEGORIES  -->
        <CoreContainer v-if="topCategories?.length" v-slot="{ labelId }" labelled>
            <BaseContainerContent :padding="{ top: 'none', right: 'normal-only', left: 'lg:small-only' }">
                <h2 :id="labelId" class="vut-h2 text-center">
                    {{ $t('pages.homepage.top_categories') }}
                </h2>
            </BaseContainerContent>

            <BaseContainerContent :padding="{ top: 'none', right: 'normal-only', bottom: 'large', left: 'lg:small-only' }" class="vut-image-menu">
                <ImageMenuCard
                    v-for="category in topCategories"
                    :key="category.id!"
                    :to="category.getUrl() ?? ''"
                    :image-url="category.thumbnailImageUrl ?? category.imageUrl"
                    :label="category.name"
                />
            </BaseContainerContent>
        </CoreContainer>

        <!--  -------------------------------------------------------------------------------- -->
        <!--  TOP PRODUCTS BY CATEGORIES  -->
        <CoreContainer v-if="productListProducts?.length" v-slot="{ labelId }" labelled>
            <BaseContainerContent :padding="{ top: 'none', right: 'normal-only', left: 'lg:small-only' }">
                <h2 :id="labelId" class="vut-h2 text-center">
                    {{ $t('pages.homepage.top_products') }}
                </h2>
            </BaseContainerContent>

            <BaseContainerContent :padding="{ top: 'none', right: 'normal-only', bottom: 'large', left: 'lg:small-only' }">
                <UiTabSwitcher
                    v-model="selectedTabOption"
                    :options="tabOptions"
                />

                <CoreUiTabContainer
                    v-for="(category, index) in productLists"
                    :key="category.id!"
                    :option="tabOptions[index]"
                    :selected-option="selectedTabOption"
                >
                    <ProductList class="vut-product-grid">
                        <ProductCard v-for="product in productListProducts"
                                     :key="product.id!"
                                     :product="product.product"
                                     :loading="productListProductsStatus === 'pending'"
                        />
                    </ProductList>
                </CoreUiTabContainer>
            </BaseContainerContent>
        </CoreContainer>

        <!--  -------------------------------------------------------------------------------- -->
        <!--  TOP ARTICLES  -->
        <CoreContainer v-if="topArticles?.length" v-slot="{ labelId }" labelled>
            <BaseContainerContent :padding="{ top: 'none', right: 'normal-only', left: 'lg:small-only' }">
                <h2 :id="labelId" class="vut-h2 text-center">
                    {{ $t('pages.blog.news') }}
                </h2>
            </BaseContainerContent>

            <BaseContainerContent :padding="{ top: 'none', right: 'normal-only', bottom: 'large', left: 'lg:small-only' }">
                <section class="vut-blog-grid">
                    <BlogArticleCard v-for="article in topArticles"
                                     :key="article.id!"
                                     :article="article"
                    />
                </section>
            </BaseContainerContent>

        </CoreContainer>
    </div>
</template>

<script setup lang="ts">
import { PRODUCT_CARD_EMBEDS } from 'assets/ts/constants/embeds'

definePageMeta({
    layout: 'homepage',
})

const runtimeConfig = useRuntimeConfig()

const PRODUCT_LIST_IDS = runtimeConfig.public.id.hpProductLists.split(',').map(Number)
const selectedProductListId = ref<number>(PRODUCT_LIST_IDS[0] ?? -1)

const [
    { items: bannersResponse },
    { items: topCategories },
    { items: productLists },
    { items: productListProducts, status: productListProductsStatus },
    { items: topArticles },
] = await Promise.all([
    useBannersApiService()
        .embed([
            BannerModel.EMBED_MOBILE_IMAGE_URL,
            BannerModel.EMBED_IMAGE_URL,
        ])
        .whereEquals(BannerModel.ATTR_IS_ACTIVE, true)
        .whereEquals(BannerModel.ATTR_BANNER_TYPE_ID, runtimeConfig.public.id.hpBanner)
        .useGet({
            ssrCache: {
                key: 'index-banners',
                ttl: 60 * 30,   // 30 min
                swr: true,
            },
        }),
    useCategoriesApiService() /* TODO BE category option - is homepage */
        .enablePagination()
        .setPerPage(6)
        .sortByAsc(CategoryModel.ATTR_POSITION)
        .onlyAttrs([
            CategoryModel.ATTR_ID,
            CategoryModel.ATTR_NAME,
        ])
        .embed([
            CategoryModel.EMBED_URLS,
            CategoryModel.EMBED_IMAGE_URL,
            CategoryModel.EMBED_THUMBNAIL_IMAGE_URL,
        ])
        .useGet({
            ssrCache: {
                key: 'index-top-categories',
                ttl: 60 * 30,   // 30 min
                swr: true,
            },
        }),
    useProductListsApiService()
        .whereIn(ProductListModel.ATTR_ID, PRODUCT_LIST_IDS)
        .useGet({
            ssrCache: {
                key: 'index-product-lists',
                ttl: 60 * 30,   // 30 min
                swr: true,
            },
        }),
    useProductListProductsApiService()
        .enablePagination()
        .setPerPage(3)
        .whereEquals(ProductListProductModel.ATTR_PRODUCT_LIST_ID, selectedProductListId)
        .embed([[
            ProductListProductModel.EMBED_PRODUCT, {
                embed: [
                    ...PRODUCT_CARD_EMBEDS,
                ],
            },
        ]])
        .useGet(),
    useArticlesApiService()
        .setPerPage(2)
        .embed([
            ArticleModel.EMBED_URLS,
            ArticleModel.EMBED_USER,
            ArticleModel.EMBED_IMAGE_URL,
            ArticleModel.EMBED_THUMBNAIL_IMAGE_URL,
            ArticleModel.EMBED_DEFAULT_ARTICLE_CATEGORY,
            ArticleModel.EMBED_TAGS,
        ])
        .sortByDesc(ArticleModel.ATTR_PUBLISH_AT)
        .useGet({
            ssrCache: {
                key: 'index-articles',
                ttl: 60 * 30,   // 30 min
                swr: true,
            },
        }),
])

const { tabOptions, selectedTabOption } = useTabSwitcher(() => productLists.value.map(list => ({ label: list.name ?? '', id: list.id! })))

watch(selectedTabOption, () => {
    selectedProductListId.value = selectedTabOption.value?.id ?? 0
})

</script>

<style scoped lang="scss">
.vut-blog-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    gap: 2.25rem 1.25rem;

    @include more-than(xl) {
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>
