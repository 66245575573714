<template>
    <NuxtLink
        :to="article?.urls ?? ''"
        class="vut-article-card"
    >
        <div class="vut-article-card__image">
            <CoreImg
                :src="article?.thumbnailImageUrl ?? article?.imageUrl"
                :alt="article?.name"
                :width="545"
                :height="352"
                loading="lazy"
                sizes="100vw xs:100vw sm:100vw md:60vw lg:60vw xl:30vw xxl:30vw"
                :placeholder="[54, 35, 100, 4]"
                class="vut-article-card__image-el"
            />
        </div>

        <div class="vut-article-card__content">
            <div class="vut-article-card__hero">
                <div v-if="article?.publishAt"
                     class="vut-article-card__date"
                >
                    <div class="vut-article-card__date-day">
                        {{ formatDateToDay(article?.publishAt) }}
                    </div>

                    <div class="vut-article-card__date-month">
                        {{ formatDateToMonthLong(article?.publishAt, locale) }}
                    </div>
                </div>

                <h3 class="vut-h3 vut-link vut-article-card__title">
                    {{ article?.name }}
                </h3>
            </div>

            <p class="line-clamp-2">
                {{ article?.perex }}
            </p>
        </div>
    </NuxtLink>
</template>

<script lang="ts" setup>
const {
    article,
} = defineProps<{
    article?: InstanceType<typeof ArticleModel> | null
}>()

const { locale } = useI18n()

</script>

<style lang="scss" scoped>
.vut-article-card {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &:not(:last-child)::after {
        content: '';
        width: calc(100% + 2rem);
        margin: 0 -1rem;
        border-bottom: hr-line();

        @include more-than(md) {
            display: none;
        }
    }
}

.vut-article-card__image {
    aspect-ratio: 1.5;
    overflow: hidden;
}

.vut-article-card__image-el {
    width: 100%;
    height: 100%;
    object-fit: cover;

    transition: transform $vut-trans-time $vut-timing;
}

.vut-article-card:hover .vut-article-card__image-el {
    transform: scale(1.04);
}

.vut-article-card__content {
    padding: 0 0 1rem;

    @include more-than(xl) {
        padding: 0 2rem 1rem;
    }
}

.vut-article-card__hero {
    flex: 1;
    display: flex;
    gap: 1rem;

    margin-bottom: 1rem;

    @include more-than(xl) {
        gap: 2rem;
        margin-bottom: 1.5rem;
    }
}

.vut-article-card__title {
    text-decoration: underline;
}

.vut-article-card:hover .vut-article-card__title {
    color: $vut-c-secondary-darker;
    text-decoration: none;
}

.vut-article-card__date {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.375rem;

    height: 5.125rem;
    width: 5.125rem;

    background: $vut-c-white;
    border: 4px solid $vut-c-ivory;
    outline: 1px solid $vut-c-platinum;

    z-index: 1;

    @include more-than(xl) {
        gap: 0.2rem;

        margin-top: -2.5rem;

        height: 6.25rem;
        width: 6.25rem;

        border: 6px solid $vut-c-ivory;
    }
}

.vut-article-card__date-day {
    font-size: 2.75rem;
    line-height: 2.5rem;
    font-family: $vut-ff-secondary;
}

.vut-article-card__date-month {
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-family: $vut-ff-secondary;
    letter-spacing: $vut-letter-spacing-large;
    text-transform: uppercase;
}
</style>
